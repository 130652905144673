import { createContext, useContext, useState } from "react";
import { slugify } from "../helpers/slugify";

const BlogContext = createContext();

export const BlogProvider = ({ children }) => {
    const [blogs, setBlogs] = useState([]);

    const updateBlogs = newBlogs => {
        setBlogs(newBlogs);
    };

    const getBlogByDocumentId = documentId => {
        return blogs.find(blog => blog.documentId === documentId);
    };

    const getBlogBySlug = slug => {
        return blogs.find(blog => slugify(blog.Title) === slug);
    };

    return <BlogContext.Provider value={{ blogs, updateBlogs, getBlogByDocumentId, getBlogBySlug }}>{children}</BlogContext.Provider>;
};

export const useBlogContext = () => useContext(BlogContext);
