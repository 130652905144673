import { Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuildingUser, faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { motion, useInView } from "framer-motion";
import { fadeInUp, variantsGroupI } from "../helpers/animations";
import { useRef } from "react";
import BillDeLisle from "../assets/Team/bill.jpg";
import MikkelBOttesen from "../assets/Team/mikkel.jpg";
import AnnaL from "../assets/Team/anna.jpg";

const profiles = [
    {
        name: "Mikkel O. Lønstrup",
        img: MikkelBOttesen,
        role: "Management & Sales",
        phone: "+45 2130 1240",
        email: "mikkel@radioanalyzer.com",
        whatsapp: "+45 2130 1240",
    },
    {
        name: "Bill De Lisle",
        img: BillDeLisle,
        role: "Sales",
        phone: "+45 5114 1240",
        email: "bill@radioanalyzer.com",
        whatsapp: "+49 172 5442732",
    },
    {
        name: "Anna Lizak",
        img: AnnaL,
        role: "Client Support",
        phone: "+45 2947 1240",
        email: "anna@radioanalyzer.com",
        whatsapp: "+45 2947 1240",
    },
];

const ContactDetail = ({ icon, link, target, children }) => (
    <div className="d-flex align-items-center mt-1">
        {icon ? <FontAwesomeIcon icon={icon} size="1x" className="pe-1" /> : null}
        <a href={link} target={target} className="color-primary-yellow-hover text-decoration-none">
            {children}
        </a>
    </div>
);

const ProfileCard = ({ profile }) => (
    <div>
        <div className="mb-1">
            <img src={profile.img} alt={profile.name} className="profile-img rounded-circle" />
        </div>
        <h2 className="fs-2">{profile.name}</h2>
        <span className="fs-4">{profile.role}</span>
        <div className="mt-2 d-flex flex-column">
            <ContactDetail icon={faPhone} link={`tel:${profile.phone}`}>
                {profile.phone}
            </ContactDetail>
            <ContactDetail icon={faEnvelope} link={`mailto:${profile.email}`}>
                {profile.email}
            </ContactDetail>
            <ContactDetail icon={faWhatsapp} link={`https://wa.me/${profile.whatsapp.replace(/\s/g, "")}`} target="_blank" rel="noopener noreferrer">
                Whatsapp: {profile.whatsapp}
            </ContactDetail>
        </div>
    </div>
);

export default function Contact() {
    const refMap = useRef(null);
    const isMapInView = useInView(refMap, { once: true });
    return (
        <>
            <Container>
                <Row className="mb-5 pb-5 position-relative line-bottom">
                    <h1 className="fs-9 my-5 text-uppercase text-center">Contact</h1>
                    {profiles.map((profile, i) => (
                        <Col xs={12} md={4} key={profile.name} className="d-flex flex-column align-items-center text-center mb-5">
                            <motion.div custom={i} initial="hidden" animate="visible" variants={variantsGroupI}>
                                <ProfileCard profile={profile} />
                            </motion.div>
                        </Col>
                    ))}
                </Row>

                <Row className="mt-5 pt-5">
                    <Col xs={12} md={6} className="text-center text-md-start">
                        <h1 className="fs-1 fw-bold">Get In Touch</h1>
                        <span className="pe-md-5 me-xl-5 d-block">
                            We’d love to hear from you! Whether you have questions, need support, or want to learn more about our services, our team is here to
                            help.
                        </span>
                    </Col>

                    <Col xs={12} md={3} className="mt-3 mt-md-0 d-flex flex-column align-items-center text-center align-items-md-start text-md-start">
                        <div className="bg-yellow square rounded-2 mb-2 d-flex align-items-center justify-content-center">
                            <FontAwesomeIcon icon={faBuildingUser} className="icone-size1-5" />
                        </div>
                        <h2 className="fs-4 fw-bold mb-1">Address</h2>
                        <ContactDetail
                            link="https://www.google.com/maps/place/RadioAnalyzer+ApS/@57.0481393,9.9236455,16z/data=!3m1!4b1!4m6!3m5!1s0x4649328d3bf9a279:0xae17a784ae6d91a1!8m2!3d57.0481393!4d9.9236455!16s%2Fg%2F11cnd47t_b?entry=ttu"
                            target="_blank">
                            Nytorv 11, 3rd floor
                            <br />
                            9000 Aalborg, Denmark
                        </ContactDetail>
                    </Col>
                    <Col xs={12} md={3} className="mt-3 mt-md-0 d-flex flex-column align-items-center text-center align-items-md-start text-md-start">
                        <div className="bg-yellow square rounded-2 mb-2 d-flex align-items-center justify-content-center">
                            <FontAwesomeIcon icon={faPhone} className="icone-size1-5" />
                        </div>
                        <h2 className="fs-4 fw-bold mb-1" ref={refMap}>
                            Contact
                        </h2>

                        <ContactDetail link={`tel:+4521301240`}>+45 2130 1240</ContactDetail>
                        <ContactDetail link={`mailto:info@radioanalyzer.com`}>info@radioanalyzer.com</ContactDetail>
                    </Col>

                    <motion.div initial={fadeInUp.initial} animate={isMapInView ? fadeInUp.animate : fadeInUp.initial} transition={fadeInUp.transition}>
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4340.4089774228805!2d9.923531299999999!3d57.0480392!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4649328d3bf9a279%3A0xae17a784ae6d91a1!2sRadioAnalyzer%20ApS!5e0!3m2!1sfr!2sdk!4v1726818839252!5m2!1sfr!2sdk"
                            className="w-100 mt-5 mt-md-4"
                            height={400}
                            style={{ border: 0 }}
                            allowFullScreen=""
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                            title="Google Maps"></iframe>
                    </motion.div>
                </Row>
            </Container>
        </>
    );
}
