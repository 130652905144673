import { useRef, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import "swiper/swiper-bundle.css";

export const GenericAutoSliderSwiper = ({
    slides,
    children,
    numberSlides = 1,
    mdNumberSlides,
    lgNumberSlides,
    addSpaceForArrow = "mx-md-5",
    arrows = true,
    swipeDelay = 2000,
    autoSwipe = true,
}) => {
    const swiperRef = useRef(null);
    const [isLoopEnabled, setIsLoopEnabled] = useState(false);
    const [isTabletOrLarger, setIsTabletOrLarger] = useState(window.innerWidth >= 768);

    useEffect(() => {
        const updateLoop = () => {
            const width = window.innerWidth;
            let slidesPerView = numberSlides;

            if (width >= 1024) {
                slidesPerView = lgNumberSlides || mdNumberSlides || numberSlides;
            } else if (width >= 768) {
                slidesPerView = mdNumberSlides || numberSlides;
            }

            setIsLoopEnabled(slides.length > slidesPerView);
            setIsTabletOrLarger(width >= 768);
        };

        updateLoop();
        window.addEventListener("resize", updateLoop);

        return () => {
            window.removeEventListener("resize", updateLoop);
        };
    }, [slides, numberSlides, mdNumberSlides, lgNumberSlides]);

    return (
        <div className="swiper-container">
            <Swiper
                ref={swiperRef}
                pagination={{ clickable: true, dynamicBullets: true }}
                slidesPerView={numberSlides}
                slidesPerGroup={numberSlides}
                autoplay={autoSwipe ? { delay: swipeDelay, disableOnInteraction: false } : false}
                loop={isLoopEnabled}
                spaceBetween={20}
                speed={swipeDelay}
                modules={[Pagination, Autoplay]}
                breakpoints={{
                    768: { slidesPerView: mdNumberSlides || numberSlides, slidesPerGroup: mdNumberSlides || numberSlides },
                    1024: { slidesPerView: lgNumberSlides || mdNumberSlides || numberSlides, slidesPerGroup: lgNumberSlides || mdNumberSlides || numberSlides },
                }}
                className={`nestedSwiper ${addSpaceForArrow}`}>
                {slides.map((slideData, index) => (
                    <SwiperSlide key={index} className="d-flex align-items-stretch h-auto cursor-pointer justify-content-center pb-3">
                        {children(slideData)}
                    </SwiperSlide>
                ))}
            </Swiper>

            {arrows && isTabletOrLarger && slides.length >= 3 ? (
                <>
                    <div className="swiper-button-next" onClick={() => swiperRef.current.swiper.slideNext()} />
                    <div className="swiper-button-prev" onClick={() => swiperRef.current.swiper.slidePrev()} />
                </>
            ) : null}
        </div>
    );
};
