export default function CardReview({ name, img, review, shortCommentStyle, children }) {
    return (
        <>
            <div className="d-flex justify-content-center">
                <img src={img} alt={name} className="object-fit w-25" />
            </div>
            <span className={shortCommentStyle ? "card-blog-text" : ""}>{review}</span>
            <span className="color-primary-blue text-center pt-2 pb-4 fw-bold">{name}</span>
            <div className="d-flex flex-column align-items-center">{children}</div>
        </>
    );
}
