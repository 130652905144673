import { useState, useEffect, useMemo } from "react";
import { useLoaderData } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import SocialMedia from "../components/SocialMedia";
import CallToActionAndNewsletter from "../components/CallToActionAndNewsletter";
import CardBlog from "../components/CardBlog";
import { GenericAutoSliderSwiper } from "../helpers/GenericAutoSliderSwiper";
import FriendsOfRadioBlog from "../assets/Blog/FriendsOfRadioBlog.png";
import { BlogPagination } from "../helpers/BlogPagination";
import { slugify } from "../helpers/slugify";
import { useBlogContext } from "../context/BlogContext";

const BlogSwiper = ({ blogs }) => {
    return (
        <GenericAutoSliderSwiper slides={blogs} numberSlides={1} addSpaceForArrow="" autoSwipe={true}>
            {blog => (
                <CardBlog
                    link={`/${slugify(blog.Title)}`}
                    state={{ documentId: blog.documentId, article: blog }}
                    img={`${process.env.REACT_APP_WEBSITE_URL_BACKEND}${blog.Banner.url}`}
                    alt={blog.Banner.alternativeText || "Image de l'article"}
                    title={blog.Title}
                    caption={blog.Caption}
                    variant="alternative"
                />
            )}
        </GenericAutoSliderSwiper>
    );
};
const CategorySlider = ({ blogs, category }) => {
    return (
        <div key={category}>
            <h2 className="pt-3 position-relative border-bottom-yellow-title text-nowrap">{category}</h2>

            <GenericAutoSliderSwiper slides={blogs} numberSlides={1} mdNumberSlides={3} autoSwipe={false}>
                {blog => (
                    <CardBlog
                        link={`/${slugify(blog.Title)}`}
                        state={{ documentId: blog.documentId, article: blog }}
                        img={`${process.env.REACT_APP_WEBSITE_URL_BACKEND}${blog.Banner.url}`}
                        alt={blog.Banner.alternativeText || "picture"}
                        title={blog.Title}
                        caption={blog.Caption}
                    />
                )}
            </GenericAutoSliderSwiper>
        </div>
    );
};

export default function Blog() {
    const { data } = useLoaderData();
    const { updateBlogs } = useBlogContext();
    const blogs = useMemo(() => data?.data || [], [data]);

    useEffect(() => {
        updateBlogs(blogs);
    }, [blogs, updateBlogs]);

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 6;

    const handlePageChange = pageNumber => {
        setCurrentPage(pageNumber);
    };

    const getPaginatedBlogs = (blogs, currentPage, itemsPerPage) => {
        const indexOfLastBlog = currentPage * itemsPerPage;
        const indexOfFirstBlog = indexOfLastBlog - itemsPerPage;
        return blogs.slice(indexOfFirstBlog, indexOfLastBlog);
    };

    const sortedBlogs = [...blogs].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    const latestBlogs = sortedBlogs.slice(0, 3);

    const categories = Array.from(new Set(sortedBlogs.map(blog => blog.Category)));

    const totalPages = Math.ceil(sortedBlogs.length / itemsPerPage);
    const currentBlogs = getPaginatedBlogs(sortedBlogs, currentPage, itemsPerPage);

    return (
        <>
            <Container>
                <Row className="py-3 py-sm-5 mt-4">
                    <Col xs={12} lg={8} className="d-none d-lg-block">
                        <BlogSwiper blogs={latestBlogs} />
                    </Col>
                    <Col xs={12} lg={4}>
                        <div>
                            <div className="w-100 h-100">
                                <img src={FriendsOfRadioBlog} alt="Friends of radio blog" className="w-100 h-100 rounded-top-2" />
                            </div>
                            <p className="mt-2 mt-lg-1">
                                We believe the radio industry is at a crossroad. Standing at an intersection that can lead to a prosperous future for radio if
                                we collectively wisen up and start fighting newcomers with modern methods and products. To get there we believe sharing what we
                                learn is key, so we take time to share some of the key insights, new ideas and brilliant minds that cross our path out there in
                                the radio world.
                            </p>
                            <div className="d-flex justify-content-end">
                                <SocialMedia />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>

            <CallToActionAndNewsletter
                title="are you a true friend of radio?"
                description="You can find the articles, videos, and graphics here. Are you interested in our products?"
                link="/book-a-demo"
                addClass="my-md-5"
                nameLink="Show me how"
                variant="newsletter"
            />
            <Container>
                {categories.map(category => (
                    <CategorySlider key={category} blogs={sortedBlogs.filter(blog => blog.Category === category)} category={category} />
                ))}

                <Row>
                    <div>
                        <h2 className="pt-5 position-relative border-bottom-yellow-title text-nowrap">All Blog Posts</h2>
                    </div>
                    {currentBlogs.map(blog => (
                        <Col xs={12} sm={6} md={4} lg={4} key={blog.id} className="mb-5 ">
                            <CardBlog
                                link={`/${slugify(blog.Title)}`}
                                state={{ documentId: blog.documentId, article: blog }}
                                img={`${process.env.REACT_APP_WEBSITE_URL_BACKEND}${blog.Banner.url}`}
                                alt={blog.Banner.alternativeText || "picture"}
                                title={blog.Title}
                                caption={blog.Caption}
                                className="h-100"
                            />
                        </Col>
                    ))}
                </Row>

                <BlogPagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
            </Container>
        </>
    );
}
