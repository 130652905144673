import Mikkel from "../assets/Team/mikkel.jpg";
import BillDeLisle from "../assets/Team/bill.jpg";
import LogoYellow from "../assets/Logo/LogoYellow.svg";

const authorProfiles = {
    "Mikkel O. Lønstrup": {
        picture: Mikkel,
        description:
            "Alumni of DJH (Danish School of Journalism) and eternally devoted to improve mass communication. Has excelled as Journalist, Columnist, Commentator, DJ, News Director, Sports Editor, Sub Chief Editor, Innovation Director and Radio Manager. Started in radio at age 14 and isn’t planning to quit anytime soon.",
    },
    "Mikkel Ottesen Lønstrup": {
        picture: Mikkel,
        description:
            "Alumni of DJH (Danish School of Journalism) and eternally devoted to improve mass communication. Has excelled as Journalist, Columnist, Commentator, DJ, News Director, Sports Editor, Sub Chief Editor, Innovation Director and Radio Manager. Started in radio at age 14 and isn’t planning to quit anytime soon.",
    },
    "Bill De Lisle": {
        picture: BillDeLisle,
        description:
            "You might say the poor guy never had a chance, being born to two radio-crazy ArmedForcesNetwork journalists that met in southeast Asia (think “Good Morning Vietnam” – and no, his dad is not Adrian Cronauer). Since discovering his love for music programming as a teenager, Bill has been obsessed with turning great ideas into numbers you can measure, and measured numbers into actionable programming strategies that make great radio. After touring Central Europe as a Music-Promotion-Programm- Director and a Research&Program Consultant for European and International Consulting firms, he has returned to the audio innovation trenches at RadioAnalyzer, and is loving every minute of it.",
    },
    "Radio Analyzer": {
        picture: LogoYellow,
    },
};

export default function ProfileAuthor({ author }) {
    const authorProfile = authorProfiles[author];
    return (
        <div className="d-flex flex-column flex-md-row align-items-start justify-content-center bg-blue-primary p-5 p-md-4 rounded-2">
            <img className="rounded-circle" src={authorProfile?.picture} alt={author} width={100} height={100} />
            <div className="d-flex flex-column ms-md-4 text-white text-center text-md-start">
                <span className="text-uppercase fw-bold fs-4 py-2 py-md-0">{author}</span>
                <p className="fs-10">{authorProfile?.description}</p>
            </div>
        </div>
    );
}
