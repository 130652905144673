import nrkP1 from "../assets/OurClients/nrk_p1.png";
import kiss988 from "../assets/OurClients/kiss.png";
import funx from "../assets/OurClients/funx.png";

export const ClientsReviews = [
    {
        name: "Bjørn Torre Grøtte, PD (NRK)",
        img: nrkP1,
        review: `RadioAnalyzer is of great benefit to me in my work every single day.
        
        The tools provide a very quick overview of the current market situation, but what’s more is that I can also zoom all the way in to a single hour or daypart and analyze in detail how it has developed over time.
        
        I often use the function Clocks to remind producers and hosts about stuff we all believe to be true, but that sometimes needs to be confirmed with actual data and fresh examples: like the fact that listeners will switch off if we talk for too long in a messy voicebreak on an uninteresting topic.
        
        I have used the tool on both the youth-focused channel NRK P3 that I was responsible for earlier, and on NRK P1, the biggest radio station in the country, for which I am now caretaker.
        
        Oh, and it’s an added bonus that the RadioAnalyzer tools make it easy for me to always know just how amazingly often the commercial radios play a Toto-song.`,
    },
    {
        name: "Armin Braun, Group PD",
        img: kiss988,
        review: `One of the most vital ingredients for a successful future of audio is listener-centered thinking. As a consequence, we need to know exactly how listeners react to our program; RadioAnalyzer provides you with that exact knowledge.
        
        Gut feelings are nice, but real ratings are much nicer – and faster – than any market research! RadioAnalyzer tells you the truth! For every single song, every DJ break, and every idea on the air.
        
        RadioAnalyzer allows me to judge experiments very quickly. One example: When we tested “the 80s Friday” at Berliner Rundfunk for the first time, the ratings were significantly higher compared to an average Friday. So we decided to run it as a serial – with great success.
        
        The usability is on point, and with the new mobile version, you can check the health of your station and your best and worst performing break deltas anywhere.
        
        RadioAnalyzer is “carpe diem” for every PD, and sometimes makes you happier than watching the latest Jimmy Kimmel video.`,
    },
    {
        name: "Niels Hoogland, PD (FunX)",
        img: funx,
        review: `We are all driven by both our brains and our instincts. At NPO FunX we pick our audience’s brain through traditional research, but the results we get from that are not the whole story! 
        
        RadioAnalyzer shows how our audience actually behaves, including their instinctive decisions.
        
        By decoding this “subconscious information,” RadioAnalyzer helps us to do a better job at programming NPO FunX to fit the taste of our listeners. Because after all, talk is cheap… (Try telling that to a DJ!)`,
    },
];
