import React from "react";

export const BlogPagination = ({ totalPages, currentPage, onPageChange }) => {
    const handlePageChange = page => {
        if (page !== currentPage && page >= 1 && page <= totalPages) {
            onPageChange(page);
        }
    };

    const renderPageNumbers = () => {
        const pages = [];
        const maxVisiblePages = 3;

        let startPage = Math.max(1, currentPage - 1);
        let endPage = Math.min(totalPages, currentPage + 1);

        if (currentPage === 1) {
            endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);
        } else if (currentPage === totalPages) {
            startPage = Math.max(1, endPage - maxVisiblePages + 1);
        }

        for (let page = startPage; page <= endPage; page++) {
            pages.push(
                <li key={page} className={`page-item ${page === currentPage ? "active" : ""}`}>
                    <button
                        className="page-link"
                        onClick={() => handlePageChange(page)}>
                        {page}
                    </button>
                </li>
            );
        }
        return pages;
    };

    return (
        <nav aria-label="Page navigation">
            <ul className="pagination justify-content-center">
                <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
                    <button
                        className="page-link"
                        aria-label="Previous"
                        onClick={() => handlePageChange(currentPage - 1)}>
                        <span aria-hidden="true">&laquo;</span>
                    </button>
                </li>
                {renderPageNumbers()}
                <li className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}>
                    <button
                        className="page-link"
                        aria-label="Next"
                        onClick={() => handlePageChange(currentPage + 1)}>
                        <span aria-hidden="true">&raquo;</span>
                    </button>
                </li>
            </ul>
        </nav>
    );
};

export default BlogPagination;
