import { LinkButtonStyled } from "./GeneralComponents/LinkButtonStyled";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

export default function Card({ img, alt, title, description, featuresList, link, icon, ImgWidth, ImgHeight }) {
    return (
        <motion.div whileHover={{ scale: 1.02 }} whileTap={{ scale: 1 }} className="col-12 mb-3 h-100 cursor-pointer shadow-1 rounded-2">
            <Link to={link} className="text-decoration-none text-dark">
                <div className="card h-100 shadow-sm rounded-2 overflow-hidden p-4">
                    <div className="text-center">
                        <img className="card-img-top" src={img} alt={alt} style={{ width: ImgWidth, height: ImgHeight, objectFit: "cover" }} />
                    </div>
                    <div className="card-body d-flex flex-column">
                        <h5 className="color-primary-blue text-center text-uppercase color-primary-yellow-hover">{title}</h5>
                        {description ? <p className="card-text text-center">{description}</p> : null}
                        {featuresList
                            ? featuresList.map(feature => {
                                  return (
                                      <li className="card-text text-center" key={feature.feature}>
                                          {feature.feature}
                                      </li>
                                  );
                              })
                            : null}
                    </div>
                    {link ? (
                        <div className="d-flex justify-content-center pb-4">
                            <LinkButtonStyled variant="dark" link={link}>
                                <span>Learn More</span>
                            </LinkButtonStyled>
                        </div>
                    ) : null} 
                </div>
            </Link>
        </motion.div>
    );
}
