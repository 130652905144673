import { Col, Container, Row } from "react-bootstrap";
import Soundwave from "../assets/Icons/Soundwave.svg";
import ProductsSection from "../components/ProductsSection";
import { LinkButtonStyled } from "../components/GeneralComponents/LinkButtonStyled";
import { ourClients } from "../data/OurClients";
import { ClientsReviews } from "../data/ClientsReviews";
import CardReview from "../components/CardReview";
import CallToActionAndNewsletter from "../components/CallToActionAndNewsletter";
import { useInView } from "framer-motion";
import { useRef } from "react";
import { motion } from "framer-motion";
import { fadeInUp, fadeInLeft, fadeInDelay } from "../helpers/animations";
import { ModalComponent } from "../components/ModalComponent";
import { OurPartners } from "../data/OurPartners";
import { GenericAutoSliderSwiper } from "../helpers/GenericAutoSliderSwiper";

const funFacts = [
    { id: 1, value: "720+", label: "Stations tracked" },
    { id: 2, value: "15+", label: "Unique Countries" },
    { id: 3, value: "125+", label: "Client Stations" },
    { id: 4, value: "960K", label: "Audio hours" },
    { id: 5, value: "500K+", label: "Songs/day" },
    { id: 6, value: "9.1M", label: "Connections/day" },
];

const FunFactSection = () => (
    <div className=" py-5">
        <div className="d-flex flex-column align-items-center justify-content-center flex-lg-row p-4 my-5 bg-blue-primary w-100">
            <Container>
                <Row>
                    {funFacts.map((fact, index) => (
                        <Col key={fact.id} xs={12} lg={2} className="d-flex justify-content-center  py-lg-0 h-lg-100">
                            <div
                                className={`d-flex flex-column align-items-center text-center text-white py-3 py-lg-0 px-md-4 w-100 ${
                                    index < funFacts.length - 1 ? "border-bottom-yellow border-end-lg-yellow" : ""
                                }`}>
                                <h3 className="fs-1 fw-bold">{fact.value}</h3>
                                <span>{fact.label}</span>
                            </div>
                        </Col>
                    ))}
                </Row>
            </Container>
        </div>
    </div>
);

const HeaderSection = ({ refHeader, isHeaderInView }) => (
    <div className="home-banner position-relative">
        <Container>
            <Row>
                <Col xs={12} md={6} className="position-absolute z-1 text-center translate-middle-center-mob text-md-start" ref={refHeader}>
                    <motion.h2
                        initial={fadeInUp.initial}
                        animate={isHeaderInView ? fadeInUp.animate : fadeInUp.initial}
                        transition={fadeInUp.transition}
                        className="fs-9 text-white text-uppercase pe-md-5 mb-3">
                        Radio Insight. <span className="color-primary-yellow">Reconstructed.</span>
                    </motion.h2>
                    <motion.p
                        initial={fadeInDelay.initial}
                        animate={isHeaderInView ? fadeInDelay.animate : fadeInDelay.initial}
                        transition={fadeInDelay.transition}
                        className="fs-6 text-white pe-md-5">
                        Real-time listener insight delivered faster, cheaper, and more detailed than any traditional research can offer. Shake up your audio
                        market with smart tools and big data with over a decade of ratings based success at your fingertips
                    </motion.p>
                    <LinkButtonStyled variant="lightIcon" icon={Soundwave} link="/book-a-demo" addClass="d-inline-block">
                        <span>Get Your Insights</span>
                    </LinkButtonStyled>
                </Col>
            </Row>
        </Container>
    </div>
);

const ClientsSection = ({ refClientsSection, isClientsInView }) => (
    <Row>
        <Col xs={12} className="rounded-2 px-4 pb-5">
            <div className="d-flex flex-column">
                <motion.h2
                    initial={fadeInLeft.initial}
                    animate={isClientsInView ? fadeInLeft.animate : fadeInLeft.initial}
                    transition={fadeInLeft.transition}
                    className="fs-9 mb-3 text-uppercase text-center"
                    ref={refClientsSection}>
                    our clients
                </motion.h2>
                <motion.span
                    className="text-center mb-2"
                    initial={fadeInDelay.initial}
                    animate={isClientsInView ? fadeInDelay.animate : fadeInDelay.initial}
                    transition={fadeInDelay.transition}>
                    We have worked with many fine radio stations. <br />
                    These are some of them.
                </motion.span>
            </div>
            <GenericAutoSliderSwiper slides={ourClients} numberSlides={1} mdNumberSlides={3} lgNumberSlides={6} swipeDelay={2500}>
                {client => <img src={client.picture} alt={client.title} width={100} height={100} className="object-fit-contain" />}
            </GenericAutoSliderSwiper>
        </Col>
    </Row>
);

const ClientReviewsSection = ({ refReviews, isReviewsInView }) => (
    <Row
        className="mb-5 py-5"
        ref={refReviews}
        style={{
            transform: isReviewsInView ? "none" : "translateY(200px)",
            opacity: isReviewsInView ? 1 : 0,
            transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0s",
        }}>
        <h2 className="fs-9 mb-5 text-uppercase text-center">what do our clients say?</h2>
        {ClientsReviews.map(review => (
            <Col xs={12} md={4} className="d-flex flex-column mb-3" key={review.name}>
                <CardReview name={review.name} img={review.img} review={review.review} shortCommentStyle="true">
                    <ModalComponent name={review.name} img={review.img} review={review.review} />
                </CardReview>
            </Col>
        ))}
    </Row>
);

const PartnersSection = ({ refPartnersSection, isPartnersInView }) => (
    <Row className="my-5 py-5 position-relative line-top" ref={refPartnersSection}>
        <Col xs={12} className="d-flex flex-column align-items-center py-5 p-md-5 rounded-2 ">
            <motion.h2
                initial={fadeInLeft.initial}
                animate={isPartnersInView ? fadeInLeft.animate : fadeInLeft.initial}
                transition={fadeInLeft.transition}
                className="fs-9 mb-3 text-uppercase text-center">
                Our Partners
            </motion.h2>
            <motion.span
                className="text-center"
                initial={fadeInDelay.initial}
                animate={isPartnersInView ? fadeInDelay.animate : fadeInDelay.initial}
                transition={fadeInDelay.transition}>
                We work with your top data collection companies around the world. These are some of them.
            </motion.span>
        </Col>
        <GenericAutoSliderSwiper slides={OurPartners} numberSlides={1} lgNumberSlides={2}>
            {partner => <img src={partner.partner} alt={partner.name} className="object-fit-container our-partners-logo-size" />}
        </GenericAutoSliderSwiper>
    </Row>
);

export default function Home() {
    const refHeader = useRef(null);
    const isHeaderInView = useInView(refHeader, { once: true });

    const refClientsSection = useRef(null);
    const isClientsInView = useInView(refClientsSection, { once: true });

    const refReviews = useRef(null);
    const isReviewsInView = useInView(refReviews, { once: true });

    const refPartnersSection = useRef(null);
    const isPartnersInView = useInView(refPartnersSection, { once: true });

    return (
        <>
            <HeaderSection refHeader={refHeader} isHeaderInView={isHeaderInView} />
            <Container>
                <ProductsSection />
            </Container>
            <FunFactSection />
            <Container>
                <ClientsSection refClientsSection={refClientsSection} isClientsInView={isClientsInView} />
            </Container>
            <Row>
                <Col xs={12}>
                    <CallToActionAndNewsletter
                        title={
                            <>
                                Stop Asking Questions.
                                <b className="color-primary-yellow"> Start Observing.</b>
                            </>
                        }
                        description="Behavior reveals more than a question ever could – watch your listeners and react to their listening patterns."
                        link="/book-a-demo"
                        nameLink="Show me how"
                        variant="blue"
                    />
                </Col>
            </Row>
            <Container>
                <ClientReviewsSection refReviews={refReviews} isReviewsInView={isReviewsInView} />
                <PartnersSection refPartnersSection={refPartnersSection} isPartnersInView={isPartnersInView} />
            </Container>
        </>
    );
}
