import { useLoaderData } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { formatDateYMD } from "../helpers/formatDate";
import SocialMedia from "../components/SocialMedia";
import ProfileAuthor from "../components/ProfileAuthor";
import NotFound from "./NotFound/NotFound";
import { useBlogContext } from '../context/BlogContext';
import { useEffect } from 'react';

const BlogHeader = ({ publishedAt, title, caption, category }) => (
    <>
        <span className="color-primary-blue">
            Published <time dateTime={publishedAt}>{formatDateYMD(publishedAt)}</time>
        </span>
        <h1 className="text-center">{title}</h1>
        <p className="text-center max-w-750">{caption}</p>
        <div className="mb-4">
            <span className="badge bg-gradient-yellow color-primary-blue me-2">{category}</span>
        </div>
    </>
);

const BlogBanner = ({ banner }) =>
    banner && (
        <div className="mb-5">
            <img
                className="card-img-top h-auto"
                style={{ width: "900px" }}
                src={`${process.env.REACT_APP_WEBSITE_URL_BACKEND}${banner.url}`}
                alt={banner.alternativeText || "Image de l'article"}
            />
        </div>
    );

const BlogContent = ({ content, author }) => (
    <div className="w-100 max-w-1150">
        <div className="blog-content" dangerouslySetInnerHTML={{ __html: content }} />
        <div className="d-flex flex-column justify-content-center w-100 my-3">
            <ProfileAuthor author={author} />
            <div className="d-flex justify-content-center mt-5">
                <SocialMedia />
            </div>
        </div>
    </div>
);

export default function BlogArticle() {
    const { slug, blogs } = useLoaderData();
    const { getBlogBySlug, updateBlogs } = useBlogContext();

    useEffect(() => {
        if (blogs) {
            updateBlogs(blogs);
        }
    }, [blogs, updateBlogs]);

    const blog = getBlogBySlug(slug);

    if (!blog) return <NotFound />;

    return (
        <Container>
            <Row>
                <Col>
                    <article className="d-flex flex-column align-items-center justify-content-center py-5 blog-article">
                        <BlogHeader publishedAt={blog.publishedAt} title={blog.Title} caption={blog.Caption} category={blog.Category} />
                        <BlogBanner banner={blog.Banner} />
                        <BlogContent content={blog.Content} author={blog.Author} />
                    </article>
                </Col>
            </Row>
        </Container>
    );
}
