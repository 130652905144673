import "bootstrap/dist/css/bootstrap.min.css";
import { createBrowserRouter } from "react-router-dom";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound/NotFound";
import Layout from "./pages/Layout";
import Error from "./pages/Error/Error";
import Contact from "./pages/Contact";
import About from "./pages/About";
import Jobs from "./pages/Jobs";
import TermsConditions from "./pages/TermsConditions";
import PrivacyCookiePolicy from "./pages/PrivacyCookiePolicy";
import BookADemo from "./pages/BookADemo";
import Blog from "./pages/Blog";
import BlogArticle from "./pages/BlogArticle";
import Product from "./pages/Product";
import { strapiLoader } from "./data/loaders/loaders";
import Testimonial from "./pages/Testimonial";
import { BlogProvider } from "./context/BlogContext";

export const router = createBrowserRouter([
    {
        element: (
            <BlogProvider>
                <Layout />
            </BlogProvider>
        ),
        errorElement: <Error />,
        children: [
            {
                path: "/",
                element: <Home />,
            },
            {
                path: "*",
                element: <NotFound />,
            },
            {
                path: "/contact",
                element: <Contact />,
            },
            {
                path: "/about",
                element: <About />,
            },
            {
                path: "/jobs",
                element: <Jobs />,
            },
            {
                path: "/terms-conditions",
                element: <TermsConditions />,
            },
            {
                path: "/privacy-cookie-policy",
                element: <PrivacyCookiePolicy />,
            },
            {
                path: "/product/:slug",
                element: <Product />,
            },
            {
                path: "/book-a-demo",
                element: <BookADemo />,
            },
            {
                path: "/blog",
                element: <Blog />,
                loader: strapiLoader(`${process.env.REACT_APP_WEBSITE_URL_BACKEND}/api/blogs?populate=*`),
            },
            {
                path: "/:slug",
                element: <BlogArticle />,
                loader: async ({ params }) => {
                    const response = await fetch(`${process.env.REACT_APP_WEBSITE_URL_BACKEND}/api/blogs?populate=*`);
                    const data = await response.json();
                    return { slug: params.slug, blogs: data.data };
                },
            },
            {
                path: "/testimonial",
                element: <Testimonial />,
            },
        ],
    },
]);
