import { Outlet, useNavigation } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import ScrollToTop from "../helpers/scrollToTop";
import Loading from "./Loading";

export default function Layout() {
    const navigation = useNavigation();
    const isLoading = navigation.state === "loading";

    return (
        <div className="overflow-hidden layout-container">
            <ScrollToTop />
            <Header />

            <main className="page-wrap pb-5 mt-5 bg-blue-light">{isLoading ? <Loading /> : <Outlet />}</main>

            <Footer />
        </div>
    );
}
