import { Col, Container, Row } from "react-bootstrap";
import Banner from "../components/GeneralComponents/Banner";
import CallToActionAndNewsletter from "../components/CallToActionAndNewsletter";

const JobIntro = () => (
    <Row className="mt-5">
        <Col xs={12} className="mb-5">
            <h1 className="fs-9 mb-3 text-uppercase text-center">come work with us!</h1>
            <span className="d-block text-center">
                Do you want to be a part of a start-up adventure on a quest to dominate Globally? Our clients – large radio stations around the World – trust
                our retrieval and calculation of data to make real business decisions. Our analytics tool tells them which songs are popular and which DJs turn
                the listeners off. We do other stuff too, and radio people lose their jobs or get promoted based on decisions they make from the data we
                deliver. So you can probably see why it is important, you really want to do your best.
            </span>
        </Col>
    </Row>
);

export default function Jobs() {
    return (
        <>
            <Banner img="https://teambusiness.dk/wp-content/uploads/2021/10/Team-Business-6.jpg" alt="RadioAnalyzer" />
            <Container>
                <JobIntro />
                <Row>
                    <Col>
                        <CallToActionAndNewsletter
                            title="we are always looking for talented folks to join our team"
                            description="We don’t always know that we need you, so you’re very welcome to send us an unsolicited application. We look forward to hearing from you. Make sure to check out our open positions as well!"
                            link="mailto:job@radioanalyzer.com"
                            nameLink="Send Application"
                        />
                    </Col>
                </Row>
            </Container>
        </>
    );
}
