import { useRef } from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { cleanSlugUrl } from "../helpers/cleanSlugUrl";
import { dataProducts } from "../data/DataProducts";
import Accordion from "react-bootstrap/Accordion";
import Card from "../components/Card";
import CallToActionAndNewsletter from "../components/CallToActionAndNewsletter";
import { motion } from "framer-motion";
import { useInView } from "framer-motion";
import { fadeInUp, variantsGroupI } from "../helpers/animations";

export default function Product() {
    const refFaq = useRef(null);
    const isFaqInView = useInView(refFaq, { once: true });

    const { slug } = useParams();
    const product = dataProducts.find(item => cleanSlugUrl(item.title) === slug);

    if (!product) {
        return <div>Product not found</div>;
    }

    const { title, secondSlider, slogan, logo, alt, faqData, featureSlogan } = product;

    return (
        <>
            <Container key={slug} className="mb-5">
                <article className="d-flex flex-column align-items-center justify-content-center pt-5">
                    <h1 className="fs-8 fs-9 fw-bold text-center text-uppercase">{title}</h1>
                    <p className="fs-1 mb-5 text-center text-uppercase">{slogan}</p>
                    <div className="w-50 h-50 mt-3 mx-auto d-md-none">
                        <img className="w-100" src={logo} alt={alt} />
                    </div>

                    <Row className="mb-5">
                        {product.productDescription.map((item, i) => {
                            return item.isImage && item.image ? (
                                <Col md={4} key={item.id} className="mt-4 text-center d-none d-md-flex">
                                    <div className="w-50 h-50 mt-3 mx-auto position-relative">
                                        <img className="w-100" src={item.image} alt={item.title} />
                                        {item.badgeArray.map((badge, j) => {
                                            return (
                                                <div key={j} className={badge.classNameBadge}>
                                                    <img
                                                        src={badge.badge}
                                                        alt="badge"
                                                        width={100}
                                                        height={100}
                                                        className="object-fit-contain d-none d-xl-flex"
                                                    />
                                                </div>
                                            );
                                        })}
                                    </div>
                                </Col>
                            ) : (
                                <motion.div
                                    custom={i}
                                    initial="hidden"
                                    animate="visible"
                                    variants={variantsGroupI}
                                    key={item.id}
                                    className={`ps-5 ps-md-0 position-relative col-md-4 ${item.additionalStyleParentDiv}`}>
                                    <h4 className="fw-bold">{item.title}</h4>
                                    <span>{item.description}</span>
                                    <div className={item.classNameBadge}>
                                        <img src={item.badge} alt={item.title} width={25} height={25} />
                                    </div>
                                </motion.div>
                            );
                        })}
                    </Row>

                    <Row className="my-5 py-5 mb-3 px-4 rounded-2 position-relative line-top">
                        <h2 className="fs-9 mt-5 text-uppercase text-center">Features</h2>
                        <p className="fs-1 mb-5 text-center text-uppercase">{featureSlogan}</p>

                        {secondSlider.map((feature, i) => (
                            <Col md={4} key={i} className="d-flex mb-4">
                                <Card
                                    key={feature.id}
                                    img={feature.img}
                                    alt={feature.alt}
                                    title={feature.title}
                                    description={feature.description}
                                    ImgHeight={50}
                                    ImgWidth={50}
                                    className="h-100"
                                />
                            </Col>
                        ))}
                    </Row>

                    <Row className="w-100 position-relative line-top my-5 pt-5">
                        <motion.div
                            initial={fadeInUp.initial}
                            animate={isFaqInView ? fadeInUp.animate : fadeInUp.initial}
                            transition={fadeInUp.transition}
                            ref={refFaq}>
                            <Col sm={12}>
                                <h2 className="fs-9 my-5 text-uppercase text-center">Frequently asked questions</h2>
                                <Accordion defaultActiveKey="0">
                                    {faqData.map((faq, index) => (
                                        <Accordion.Item eventKey={index.toString()} key={faq.id} className="mb-3">
                                            <Accordion.Header>{faq.question}</Accordion.Header>
                                            <Accordion.Body>{faq.answer}</Accordion.Body>
                                        </Accordion.Item>
                                    ))}
                                </Accordion>
                            </Col>
                        </motion.div>
                    </Row>
                </article>
            </Container>

            <CallToActionAndNewsletter
                title={<span className="color-primary-yellow">HOW DOES RADIOANALYZER INCREASE LISTENING?</span>}
                description="We are happy to show you, no strings attached. Book a Free Demo Today."
                link="/book-a-demo"
                nameLink="Get free demo"
                variant="blue"
            />
        </>
    );
}
