import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitter, faYoutube, faLinkedin } from "@fortawesome/free-brands-svg-icons";

export default function SocialMedia() {
    const socialMediaLinks = [
        {
            href: "https://www.youtube.com/channel/UCGgCLTX7IssYJq-X3vQEEew",
            icon: faYoutube,
            label: "YouTube",
        },
        {
            href: "https://x.com/radioanalyzer",
            icon: faTwitter,
            label: "Twitter",
        },
        {
            href: "https://www.linkedin.com/company/radioanalyzer",
            icon: faLinkedin,
            label: "LinkedIn",
        },
    ];

    return (
        <>
            {socialMediaLinks.map(social => (
                <a
                    key={social.label}
                    href={social.href}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="color-primary-blue mx-2 d-flex align-items-center"
                    aria-label={social.label}>
                    <FontAwesomeIcon icon={social.icon} size="2x" className="fa-fw" />
                </a>
            ))}
        </>
    );
}
