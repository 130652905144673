import { Col, Container, Row } from "react-bootstrap";
import { ClientsReviews } from "../data/ClientsReviews";
import CardReview from "../components/CardReview";

export default function Testimonial() {
    return (
        <Container className="my-5">
            <Row>
                <h2 className="fs-9 mb-5 text-uppercase text-center">What Do Our Clients Say?</h2>
                {ClientsReviews.map(({ name, img, review }) => (
                    <Col xs={12} md={4} className="d-flex flex-column" key={name}>
                        <CardReview name={name} img={img} review={review} />
                    </Col>
                ))}
            </Row>
        </Container>
    );
}
